<template>
  <div class="content">
    <ApplicationNannyInputExperienceWorkingKidsAges
      v-model:kids-ages="applicationNanny.experience.working.kidsAges"
    />

    <ButtonPrimaryNext
      :to="{ name: 'ApplicationNannyCreateExperienceWorkingYears' }"
      :isDisabled="!applicationNanny.experience.working.kidsAges.length"
    />
  </div>
</template>

<script>
import ApplicationNannyInputExperienceWorkingKidsAges from "@/components/Application/Nanny/ApplicationNannyInputExperienceWorkingKidsAges.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";
import { useApplicationNannyStore } from "@/stores/applicationNanny";

export default {
  components: {
    ApplicationNannyInputExperienceWorkingKidsAges,
    ButtonPrimaryNext,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();

    return {
      applicationNanny,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}

@media (max-width: 1200px) {
}
</style>
