<template>
  <div class="container">
    <BaseH1
      :text="'Z dziećmi w jakim wieku pracowałaś?'"
      class="h1 container__h1"
    />

    <div class="wrapper-checkboxes">
      <InputCheckboxGroupSticked
        v-model="application.kidsAges"
        :name="'kids-ages'"
        :options="options"
        :columns="2"
      />
      <TextHint :text="'Możesz zaznaczyć kilka opcji'" />
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputCheckboxGroupSticked from "@/components/UI/Base/InputCheckboxGroupSticked.vue";
import TextHint from "@/components/UI/TextHint.vue";

export default {
  components: {
    BaseH1,
    InputCheckboxGroupSticked,
    TextHint,
  },

  props: {
    kidsAges: Array,
  },

  emits: ["update:kidsAges"],

  setup(props, { emit }) {
    const application = reactive({
      kidsAges: props.kidsAges,
    });

    watch(
      () => application.kidsAges,
      (newKidsAges) => emit("update:kidsAges", newKidsAges)
    );
    watch(
      () => props.kidsAges,
      (newKidsAges) => (application.kidsAges = newKidsAges)
    );

    const options = [
      {
        label: "0-3 lata",
        value: "0_3",
      },
      {
        label: "4-7 lat",
        value: "4_7",
      },
      {
        label: "8-10 lat",
        value: "8_10",
      },
      {
        label: "10+ lat",
        value: "10_",
      },
    ];

    return {
      application,
      options,
      // setRegularity,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.container__h1 {
  width: 360px;
}
.wrapper-checkboxes {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;

  row-gap: 40px;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 60px;
  }
  .container__h1 {
    width: 100%;
  }
  .wrapper-checkboxes {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;

    row-gap: 40px;
  }
}
</style>
